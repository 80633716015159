<template>
  <div>
    <PageHeader />
    <AffiliateReport />
  </div>
</template>

<script>
export default {
  page: {
    title: 'Affiliate (AF)',
  },
  components: {
    AffiliateReport: () => import('./report'),
    // AffiliateSettings: () => import('./settings'),
  },
}
</script>